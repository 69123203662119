:root {



    //fonts
    --montserrat: 'Montserrat';
    --miller :'Miller Display';
    --miller-banner:'Miller Banner';

    //size
    --text-xxs:12px;
    --text-xs:14px;
    --text-sm:16px;
    --text-md:18px;
    --text-lg:20px;
    --text-xl:22px;
    --text-xxl:24px;
    --text-xxxl:36px;
    --text-xxxxl:48px;   
    --text-70:70px;   
  }