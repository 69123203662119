.Layout { 
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    padding: 48px 20%;
    &__body{
        height: 100%;
        min-height: calc(100vh - 20vh - 98px);
        padding-top: 50px;
    }
}