.Navbar {
    &__head {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 20vh;
        width: 100%;
        position: relative;
        &__left{

        }
        &__center{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
                    h1 {
                        font-weight: 300;
                        font-size: 48.0179px;
                        line-height: 58px;
                        letter-spacing: 0.04em;
                        color: var(--light-3);
            
                    }
        }
        &__right{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}