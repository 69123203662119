@import '../src/assets/styles/css-variables.scss';
@font-face {
  font-family: "Miller Family";
  src: local("Miller Family"),
   url("../src/assets/fonts/Miller.otf") format("truetype");
  }

  body{
    font-size: var(--text-sm);
    font-family: var(--miller);
    min-height: 100vh;
    background-color: var(--whitethemed);

    &.light{
      --grey-1:#C8C8D0;
      --grey-2:#8F919B;
      --grey-2-fixed:#8F919B;
      --grey-mid:#E7EAF0;
      --grey-mid-4op:rgba(231, 234, 240, 0.4);
      --grey-light:#F0F4F9;
      --grey-hover: #A5A7AF;
      --orange:#D25C4E;
      --error :#E84353;
      --black-text:#16191F;
  
      --action-shadow:rgba(174, 141, 120, 0.4);
      --error-shadow:rgba(232, 67, 83, 0.4);
  
      //light theme
      --white: #FFFFFF;
      --light-2:#DEDCD4;
      --light-3: #0A1D42;
      --light-4:#4F5A6C;
      --light-4-2op:rgba(79, 90, 108, 0.2);
      --secondary:#3A4965;
      --action:  #AE8D78;
      --action-light:#BEA493;
      --input-focus-text:#16191F;
      --input-rest-text:#16191F;

      --actionthemed:  #AE8D78;
      --whitethemed: #FFFFFF;
    }
    &.dark{
      --grey-1:#C8C8D0;
      --grey-2:#FFFFFF;
      --grey-2-fixed:#8F919B;
      --grey-mid:#1E202A;
      --grey-mid-4op:#1E202A;
      --grey-light:#F0F4F9;
      --grey-hover: #A5A7AF;
      --orange:#D25C4E;
      --error :#E84353;
      --black-text:#FFFFFF;
  
      --action-shadow:rgba(174, 141, 120, 0.4);
      --error-shadow:rgba(232, 67, 83, 0.4);
  
      --white: #FFFFFF;
      --light-2:#DEDCD4;
      --light-3: #FFFFFF;
      --light-4:#8F919B;
      --light-4-2op:rgba(143, 145, 155, 0.2);
      --secondary:#8F919B;
      --action:  #AE8D78;
      --action-light:#BEA493;
      --input-focus-text:#FFFFFF;
      --input-rest-text:#8F919B;

      --actionthemed:  #262731;
      --whitethemed: #16171F;

    }
  }

h1,h2,h3,h4,h5,h5,p{
  margin: 0;
}
  //headings

  h1{
    font-family: var(--miller);
    font-weight: 400;
    font-size: var(--text-xxxxl);
    line-height: 50px;
  }

  h2{
    font-family: var(--miller);
    font-weight: 400;
    font-size: var(--text-xxxl);
    line-height: 43px;
  }

  h3{
    font-family: var(--miller);
              font-weight: 600;
              font-size: var(--text-xxl);
              line-height: 29px;
  }

  h4{
    font-family: var(--miller);
    font-weight: 400;
    font-size: var(--text-lg);
    line-height: 24px;
  }

  h5{
    font-family: var(--miller);
    font-weight: 700;
    font-size: var(--text-sm);
    line-height: 19px;
  }

  h6{
    font-family: var(--miller);
    font-weight: 700;
    font-size: var(--text-xs);
    line-height: 17px;
  }

  small{
    font-family: var(--miller);
    font-weight: 400;
    font-size: var(--text-xxs);
    line-height: 14px;
  }
