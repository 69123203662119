.FallbackPage {
    background-color: black;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    left: 0;
    right: 0;

    :global .ant-spin{
        color: #AE8D78 !important;
    }
}